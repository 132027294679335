/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `PRODUCT` - 상품
 * `CONTENT` - 콘텐츠 상품
 */
export type ProductTypeEnum = (typeof ProductTypeEnum)[keyof typeof ProductTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductTypeEnum = {
  PRODUCT: 'PRODUCT',
  CONTENT: 'CONTENT'
} as const
