/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `SITE_NOT_AVAILABLE` - 사이트 기상 악화
 * `SITE_BREAKING_TIME` - 사이트의 휴게시간
 * `SITE_ORDER_NOT_AVAILABLE` - 사이트의 주문가능시간 아님
 * `ORDER_CANNOT_DISPATCH` - 로봇 배차 불가
 * `USER_MOBILE_NUMBER_NOT_VERIFIED` - 유저가 인증되지 않았습니다
 */
export type OrderDocument400ExceptionCodeEnum =
  (typeof OrderDocument400ExceptionCodeEnum)[keyof typeof OrderDocument400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDocument400ExceptionCodeEnum = {
  SITE_NOT_AVAILABLE: 'SITE_NOT_AVAILABLE',
  SITE_BREAKING_TIME: 'SITE_BREAKING_TIME',
  SITE_ORDER_NOT_AVAILABLE: 'SITE_ORDER_NOT_AVAILABLE',
  ORDER_CANNOT_DISPATCH: 'ORDER_CANNOT_DISPATCH',
  USER_MOBILE_NUMBER_NOT_VERIFIED: 'USER_MOBILE_NUMBER_NOT_VERIFIED'
} as const
