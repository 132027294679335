/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `AMOUNT` - 정액 할인
 * `RATE` - 정률 할인
 */
export type DiscountTypeEnum = (typeof DiscountTypeEnum)[keyof typeof DiscountTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiscountTypeEnum = {
  AMOUNT: 'AMOUNT',
  RATE: 'RATE'
} as const
