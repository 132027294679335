/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `SITE_NEUBIE_AVAILABLE` - 뉴비 이용 가능 알림
 */
export type SubscriptionTypeEnum = (typeof SubscriptionTypeEnum)[keyof typeof SubscriptionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionTypeEnum = {
  SITE_NEUBIE_AVAILABLE: 'SITE_NEUBIE_AVAILABLE'
} as const
