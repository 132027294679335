/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `INVALID_RATE` - 잘못된 평점입니다.
 * `OVER_MAX_LENGTH` - 최대 입력 크기를 넘었습니다.
 * `ALREADY_REGISTERED` - 이미 등록되었습니다.
 */
export type OrderReviewCreate400ExceptionCodeEnum =
  (typeof OrderReviewCreate400ExceptionCodeEnum)[keyof typeof OrderReviewCreate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderReviewCreate400ExceptionCodeEnum = {
  INVALID_RATE: 'INVALID_RATE',
  OVER_MAX_LENGTH: 'OVER_MAX_LENGTH',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED'
} as const
