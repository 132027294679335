/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `NOTICE` - NOTICE
 * `EVENT` - EVENT
 */
export type BottomSheetTypeEnum = (typeof BottomSheetTypeEnum)[keyof typeof BottomSheetTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BottomSheetTypeEnum = {
  NOTICE: 'NOTICE',
  EVENT: 'EVENT'
} as const
