/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `ORDER_ESTIMATE_PATH_UNAVAILABLE` - 예상 경로 불가
 * `NODE_NUMBER_SAME` - 시작 노드 번호와 도착 노드 번호가 동일
 */
export type OrderEstimatePath400ExceptionCodeEnum =
  (typeof OrderEstimatePath400ExceptionCodeEnum)[keyof typeof OrderEstimatePath400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderEstimatePath400ExceptionCodeEnum = {
  ORDER_ESTIMATE_PATH_UNAVAILABLE: 'ORDER_ESTIMATE_PATH_UNAVAILABLE',
  NODE_NUMBER_SAME: 'NODE_NUMBER_SAME'
} as const
