/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `MOBILE_FIND_PASSWORD` - 핸드폰 번호로 비밀번호 초기화
 * `MOBILE_FIND_USERNAME` - 핸드폰 번호로 아이디 찾기
 * `MOBILE_NUMBER_VERIFICATION` - 핸드폰 번호 인증
 * `NEOM_EMAIL_VERIFICATION` - 네옴 이메일 인증
 */
export type VerificationTypeEnum = (typeof VerificationTypeEnum)[keyof typeof VerificationTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationTypeEnum = {
  MOBILE_FIND_PASSWORD: 'MOBILE_FIND_PASSWORD',
  MOBILE_FIND_USERNAME: 'MOBILE_FIND_USERNAME',
  MOBILE_NUMBER_VERIFICATION: 'MOBILE_NUMBER_VERIFICATION',
  NEOM_EMAIL_VERIFICATION: 'NEOM_EMAIL_VERIFICATION'
} as const
