/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `READY` - 대기
 * `PAID` - 결제 완료
 * `FAILED` - 결제 실패
 */
export type StatusEnum = (typeof StatusEnum)[keyof typeof StatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  READY: 'READY',
  PAID: 'PAID',
  FAILED: 'FAILED'
} as const
