/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `SITE_NOT_AVAILABLE` - 사이트 기상 악화
 * `SITE_BREAKING_TIME` - 사이트의 휴게시간
 * `SITE_ORDER_NOT_AVAILABLE` - 사이트의 주문가능시간 아님
 * `SHOP_NOT_AVAILABLE` - 가게의 수동 닫힘
 * `SHOP_BREAKING_TIME` - 가게의 휴게시간
 * `SHOP_ORDER_NOT_AVAILABLE` - 가게의 주문가능시간 아님
 * `PRODUCT_NOT_AVAILABLE` - 상품 사용 불가
 * `PRODUCT_OPTION_NOT_AVAILABLE` - 상품 옵션 사용 불가
 * `DIFFERENT_SHOP` - 장바구니에 다른 가게의 상품 담으려 함
 * `PRODUCT_OPTION_UNDER_MIN_COUNT` - 상품 옵션 최소 선택 개수 미달
 * `PRODUCT_OPTION_OVER_MAX_COUNT` - 상품 옵션 최대 선택 개수 초과
 * `ORDER_CANNOT_DISPATCH` - 로봇 배차 불가
 * `LESS_THAN_MIN_ORDER_PRICE` - 최소 주문 금액 미달
 * `CONTENT_PRODUCT_ALREADY_EXISTS` - 콘텐츠 상품은 하나만 구매 가능합니다.
 */
export type CartCreate400ExceptionCodeEnum =
  (typeof CartCreate400ExceptionCodeEnum)[keyof typeof CartCreate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CartCreate400ExceptionCodeEnum = {
  SITE_NOT_AVAILABLE: 'SITE_NOT_AVAILABLE',
  SITE_BREAKING_TIME: 'SITE_BREAKING_TIME',
  SITE_ORDER_NOT_AVAILABLE: 'SITE_ORDER_NOT_AVAILABLE',
  SHOP_NOT_AVAILABLE: 'SHOP_NOT_AVAILABLE',
  SHOP_BREAKING_TIME: 'SHOP_BREAKING_TIME',
  SHOP_ORDER_NOT_AVAILABLE: 'SHOP_ORDER_NOT_AVAILABLE',
  PRODUCT_NOT_AVAILABLE: 'PRODUCT_NOT_AVAILABLE',
  PRODUCT_OPTION_NOT_AVAILABLE: 'PRODUCT_OPTION_NOT_AVAILABLE',
  DIFFERENT_SHOP: 'DIFFERENT_SHOP',
  PRODUCT_OPTION_UNDER_MIN_COUNT: 'PRODUCT_OPTION_UNDER_MIN_COUNT',
  PRODUCT_OPTION_OVER_MAX_COUNT: 'PRODUCT_OPTION_OVER_MAX_COUNT',
  ORDER_CANNOT_DISPATCH: 'ORDER_CANNOT_DISPATCH',
  LESS_THAN_MIN_ORDER_PRICE: 'LESS_THAN_MIN_ORDER_PRICE',
  CONTENT_PRODUCT_ALREADY_EXISTS: 'CONTENT_PRODUCT_ALREADY_EXISTS'
} as const
