/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ShopAvailableStatusEnum = (typeof ShopAvailableStatusEnum)[keyof typeof ShopAvailableStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShopAvailableStatusEnum = {
  SHOP_AVAILABLE: 'SHOP_AVAILABLE',
  SHOP_NOT_AVAILABLE: 'SHOP_NOT_AVAILABLE',
  SHOP_BREAKING_TIME: 'SHOP_BREAKING_TIME',
  SHOP_ORDER_NOT_AVAILABLE: 'SHOP_ORDER_NOT_AVAILABLE'
} as const
