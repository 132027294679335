/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ShopsListOrderByItem = (typeof ShopsListOrderByItem)[keyof typeof ShopsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShopsListOrderByItem = {
  '-created_at': '-created_at',
  '-id': '-id',
  '-is_available': '-is_available',
  '-name': '-name',
  '-series': '-series',
  created_at: 'created_at',
  id: 'id',
  is_available: 'is_available',
  name: 'name',
  series: 'series'
} as const
