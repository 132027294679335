/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type OrderExpectWaitStatusEnum = (typeof OrderExpectWaitStatusEnum)[keyof typeof OrderExpectWaitStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderExpectWaitStatusEnum = {
  AVAILABLE: 'AVAILABLE',
  WAIT: 'WAIT',
  UNKNOWN: 'UNKNOWN',
  UNAVAILABLE: 'UNAVAILABLE'
} as const
