/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `TAP_REQUEST_ERROR` - Tap 결제 요청이 실패하였습니다. code={tap_error_code}, message={tap_error_message}
 * `TAP_CARD_OR_TOKEN_REQUIRED` - 카드 또는 토큰입력은 필수입니다.
 * `TAP_CARD_HASH_REQUIRED` - 토큰 결제시 card_hash 값은 필수입니다.
 * `TAP_INVALID_CARD` - 유효하지 않은 카드입니다.
 * `ORDER_PRICE_NOT_MATCH` - 결제 금액이 일치하지 않습니다. order: {order_price}, payment: {payment_price}
 * `TAP_UNSECURE_CARD_PAYMENT_LIMIT_EXCEEDED` - 3D Secure에 가입되지 않은 카드의 결제 횟수가 초과되었습니다.
 * `TRANSACTION_ALREADY_EXISTS` - 이미 진행중인 결제건이 존재합니다.
 */
export type TapPaymentReq400ExceptionCodeEnum =
  (typeof TapPaymentReq400ExceptionCodeEnum)[keyof typeof TapPaymentReq400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TapPaymentReq400ExceptionCodeEnum = {
  TAP_REQUEST_ERROR: 'TAP_REQUEST_ERROR',
  TAP_CARD_OR_TOKEN_REQUIRED: 'TAP_CARD_OR_TOKEN_REQUIRED',
  TAP_CARD_HASH_REQUIRED: 'TAP_CARD_HASH_REQUIRED',
  TAP_INVALID_CARD: 'TAP_INVALID_CARD',
  ORDER_PRICE_NOT_MATCH: 'ORDER_PRICE_NOT_MATCH',
  TAP_UNSECURE_CARD_PAYMENT_LIMIT_EXCEEDED: 'TAP_UNSECURE_CARD_PAYMENT_LIMIT_EXCEEDED',
  TRANSACTION_ALREADY_EXISTS: 'TRANSACTION_ALREADY_EXISTS'
} as const
