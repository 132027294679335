/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type SitesBottomSheetsListBottomSheetType =
  (typeof SitesBottomSheetsListBottomSheetType)[keyof typeof SitesBottomSheetsListBottomSheetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SitesBottomSheetsListBottomSheetType = {
  EVENT: 'EVENT',
  NOTICE: 'NOTICE'
} as const
