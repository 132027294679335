/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `MAX_REQUEST_COUNT_EXCEEDED` - 인증번호 요청 횟수를 초과했습니다.
 * `VERIFICATION_EXPIRED` - 인증 시간이 만료되었습니다.
 * `MOBILE_NUMBER_ALREADY_EXISTS` - 이미 가입된 번호입니다.
 * `NEOM_EMAIL_ALREADY_USED` - 해당 neom_email은 이미 사용되었습니다.
 */
export type UserRequestVerification400ExceptionCodeEnum =
  (typeof UserRequestVerification400ExceptionCodeEnum)[keyof typeof UserRequestVerification400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRequestVerification400ExceptionCodeEnum = {
  MAX_REQUEST_COUNT_EXCEEDED: 'MAX_REQUEST_COUNT_EXCEEDED',
  VERIFICATION_EXPIRED: 'VERIFICATION_EXPIRED',
  MOBILE_NUMBER_ALREADY_EXISTS: 'MOBILE_NUMBER_ALREADY_EXISTS',
  NEOM_EMAIL_ALREADY_USED: 'NEOM_EMAIL_ALREADY_USED'
} as const
