/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `CARD` - 신용카드
 * `KAKAOPAY` - 카카오페이
 * `NAVERPAY` - 네이버페이
 * `PAYCO` - 페이코
 * `SAMSUNG` - 삼성페이
 * `SAMSUNGPAY` - 삼성페이
 * `TOSSPAY` - 토스간편결제
 * `TRANS` - 실시간계좌이체
 * `USER_WALLET` - 사용자 지갑 결제
 * `VBANK` - 가상계좌
 * `ZERO_PRICE` - 무료 결제
 */
export type PayMethodTypeEnum = (typeof PayMethodTypeEnum)[keyof typeof PayMethodTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayMethodTypeEnum = {
  CARD: 'CARD',
  KAKAOPAY: 'KAKAOPAY',
  NAVERPAY: 'NAVERPAY',
  PAYCO: 'PAYCO',
  SAMSUNG: 'SAMSUNG',
  SAMSUNGPAY: 'SAMSUNGPAY',
  TOSSPAY: 'TOSSPAY',
  TRANS: 'TRANS',
  USER_WALLET: 'USER_WALLET',
  VBANK: 'VBANK',
  ZERO_PRICE: 'ZERO_PRICE'
} as const
