/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `SHOP_PERMISSION_DENIED` - 상점 접근 권한이 없습니다.
 */
export type ShopRetrieveRes403ExceptionCodeEnum =
  (typeof ShopRetrieveRes403ExceptionCodeEnum)[keyof typeof ShopRetrieveRes403ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShopRetrieveRes403ExceptionCodeEnum = {
  SHOP_PERMISSION_DENIED: 'SHOP_PERMISSION_DENIED'
} as const
