/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `DOCUMENT` - 문서
 * `FOOD` - 음식
 */
export type DeliveryTypesEnum = (typeof DeliveryTypesEnum)[keyof typeof DeliveryTypesEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryTypesEnum = {
  DOCUMENT: 'DOCUMENT',
  FOOD: 'FOOD'
} as const
