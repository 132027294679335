/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `SITE_NOT_AVAILABLE` - 사이트 기상 악화
 * `SITE_BREAKING_TIME` - 사이트의 휴게시간
 * `SITE_ORDER_NOT_AVAILABLE` - 사이트의 주문가능시간 아님
 * `SHOP_NOT_AVAILABLE` - 가게의 수동 닫힘
 * `SHOP_BREAKING_TIME` - 가게의 휴게시간
 * `SHOP_ORDER_NOT_AVAILABLE` - 가게의 주문가능시간 아님
 * `ORDER_CANNOT_DISPATCH` - 로봇 배차 불가
 * `ORDER_USER_COUPON_INVALID_SHOP` - 쿠폰을 사용할 수 없는 가게입니다.
 * `ORDER_USER_COUPON_UNDER_MIN_ORDER_AMOUNT` - 주문 금액이 쿠폰 사용 가능 금액보다 낮습니다.
 * `ORDER_USER_COUPON_INVALID_RANGE` - 쿠폰 사용 기간이 아닙니다.
 * `ORDER_USED_POINT_INSUFFICIENT_BALANCE` - 사용 포인트가 잔액보다 많습니다.
 * `ORDER_USED_POINT_GREATER_THAN_TOTAL_PRICE` - 사용 포인트가 총 주문 금액보다 많습니다.
 */
export type OrderPartialUpdate400ExceptionCodeEnum =
  (typeof OrderPartialUpdate400ExceptionCodeEnum)[keyof typeof OrderPartialUpdate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderPartialUpdate400ExceptionCodeEnum = {
  SITE_NOT_AVAILABLE: 'SITE_NOT_AVAILABLE',
  SITE_BREAKING_TIME: 'SITE_BREAKING_TIME',
  SITE_ORDER_NOT_AVAILABLE: 'SITE_ORDER_NOT_AVAILABLE',
  SHOP_NOT_AVAILABLE: 'SHOP_NOT_AVAILABLE',
  SHOP_BREAKING_TIME: 'SHOP_BREAKING_TIME',
  SHOP_ORDER_NOT_AVAILABLE: 'SHOP_ORDER_NOT_AVAILABLE',
  ORDER_CANNOT_DISPATCH: 'ORDER_CANNOT_DISPATCH',
  ORDER_USER_COUPON_INVALID_SHOP: 'ORDER_USER_COUPON_INVALID_SHOP',
  ORDER_USER_COUPON_UNDER_MIN_ORDER_AMOUNT: 'ORDER_USER_COUPON_UNDER_MIN_ORDER_AMOUNT',
  ORDER_USER_COUPON_INVALID_RANGE: 'ORDER_USER_COUPON_INVALID_RANGE',
  ORDER_USED_POINT_INSUFFICIENT_BALANCE: 'ORDER_USED_POINT_INSUFFICIENT_BALANCE',
  ORDER_USED_POINT_GREATER_THAN_TOTAL_PRICE: 'ORDER_USED_POINT_GREATER_THAN_TOTAL_PRICE'
} as const
