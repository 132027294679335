/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `CABIN_NUMBER_NOT_FOUND` - 존재하지 않는 cabin_number 입니다.
 * `CABIN_NUMBER_ALREADY_USED` - 해당 cabin_number로 이미 사용되었습니다.
 * `NEOM_EMAIL_ALREADY_USED` - 해당 neom_email은 이미 사용되었습니다.
 */
export type NeomPromotionRegister400ExceptionCodeEnum =
  (typeof NeomPromotionRegister400ExceptionCodeEnum)[keyof typeof NeomPromotionRegister400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NeomPromotionRegister400ExceptionCodeEnum = {
  CABIN_NUMBER_NOT_FOUND: 'CABIN_NUMBER_NOT_FOUND',
  CABIN_NUMBER_ALREADY_USED: 'CABIN_NUMBER_ALREADY_USED',
  NEOM_EMAIL_ALREADY_USED: 'NEOM_EMAIL_ALREADY_USED'
} as const
