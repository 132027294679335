/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `APPLE` - 애플
 * `GOOGLE` - 구글
 * `KAKAO` - 카카오
 * `NAVER` - 네이버
 */
export type SocialLoginTypeEnum = (typeof SocialLoginTypeEnum)[keyof typeof SocialLoginTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialLoginTypeEnum = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  KAKAO: 'KAKAO',
  NAVER: 'NAVER'
} as const
