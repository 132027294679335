/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `TAP_REDIRECT_URL_NOT_FOUND` - Tap 결제 요청 후 redirect_url이 없습니다.
 */
export type TapPaymentReq404ExceptionCodeEnum =
  (typeof TapPaymentReq404ExceptionCodeEnum)[keyof typeof TapPaymentReq404ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TapPaymentReq404ExceptionCodeEnum = {
  TAP_REDIRECT_URL_NOT_FOUND: 'TAP_REDIRECT_URL_NOT_FOUND'
} as const
