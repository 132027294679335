/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `USER_PUSH_SUBSCRIPTION_ALREADY_EXISTS` - 이미 해당 푸쉬알림을 구독하였습니다.
 */
export type UserPushSubscriptionCreate400ExceptionCodeEnum =
  (typeof UserPushSubscriptionCreate400ExceptionCodeEnum)[keyof typeof UserPushSubscriptionCreate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPushSubscriptionCreate400ExceptionCodeEnum = {
  USER_PUSH_SUBSCRIPTION_ALREADY_EXISTS: 'USER_PUSH_SUBSCRIPTION_ALREADY_EXISTS'
} as const
