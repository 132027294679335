/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type UsersCouponsListOrderByItem = (typeof UsersCouponsListOrderByItem)[keyof typeof UsersCouponsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersCouponsListOrderByItem = {
  '-created_at': '-created_at',
  '-ended_at': '-ended_at',
  '-id': '-id',
  '-shop': '-shop',
  '-started_at': '-started_at',
  '-used_at': '-used_at',
  created_at: 'created_at',
  ended_at: 'ended_at',
  id: 'id',
  shop: 'shop',
  started_at: 'started_at',
  used_at: 'used_at'
} as const
