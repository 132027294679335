/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `USER_ALREADY_EXISTS` - 이미 가입된 회원입니다
 */
export type SocialSignup401ExceptionCodeEnum =
  (typeof SocialSignup401ExceptionCodeEnum)[keyof typeof SocialSignup401ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialSignup401ExceptionCodeEnum = {
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS'
} as const
